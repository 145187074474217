import get from 'lodash.get';

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const titleHeirarchyMap: { [key: string]: HeadingLevel } = {
  h1: 'h2',
  h2: 'h3',
  h3: 'h4',
  h4: 'h5',
  h5: 'h6',
  h6: 'h6',
};

/* Accordion Heading Level should never exceed h2 */
export const clampAccordionHeadingLevel = (
  providedHeadingLevel: HeadingLevel,
  hasTitle: boolean
): HeadingLevel => {
  if (hasTitle) return get(titleHeirarchyMap, providedHeadingLevel);
  if (providedHeadingLevel === 'h1') return 'h2';

  return providedHeadingLevel;
};

/* Title Heading Level should never fall below h5 */
export const clampTitleHeadingLevel = (providedHeadingLevel: HeadingLevel): HeadingLevel => {
  if (providedHeadingLevel === 'h6') return 'h5';

  return providedHeadingLevel;
};
