/**
 * This is the entry point for Feature Hub App integration
 */

import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import type { Logger } from '@feature-hub/logger';
import { ReactFeatureApp } from '@feature-hub/react';
import { ContentServiceV1 } from '@oneaudi/content-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import React from 'react';
import App from './FeatureApp';
import { ContentContextProvider } from './context';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      's2:logger': '^1.0.0',
    },
    externals: {
      react: '^16.13.1 || ^17.0.2',
      'styled-components': '*',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const contentService = featureServices['audi-content-service'];
    const logger = featureServices['s2:logger'];
    logger?.info('Feature App - FAQs - created.');

    return {
      render: () => {
        return (
          <ContentContextProvider contentService={contentService as ContentServiceV1}>
            <App />
          </ContentContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
