/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { Faqs, FaqsProps } from './components/Faqs';
import { withFocusReset } from './components/Faqs/withFocusReset';
import { useContent } from './context';

const FeatureApp: React.FC = () => {
  const content = useContent<FaqsProps>();

  return <Faqs {...content} />;
};

export default withFocusReset(FeatureApp);
