import { Accordion, AccordionSection, AudiPlatformProvider, Text } from '@audi/audi-ui-react';
import React, { useMemo } from 'react';
import uuid from 'react-uuid';
import {
  HeadingLevel,
  clampAccordionHeadingLevel,
  clampTitleHeadingLevel,
} from './clampHeadingLevel';
import { parser } from './parser';
import { Container, InnerContainer } from './styles';

export type FaqConfig = {
  question: string;
  answer: string;
};
export interface FaqsProps {
  // eslint-disable-next-line react/require-default-props
  title?: string;
  headingLevel: HeadingLevel;
  faqs: FaqConfig[];
}

export const Faqs: React.FC<FaqsProps> = ({ title, faqs = [], headingLevel = 'h2' }) => {
  /* h1 - h5 */
  const titleHeadingLevel = clampTitleHeadingLevel(headingLevel);
  /* h2 - h6 */
  const accordionHeadingLevel = clampAccordionHeadingLevel(headingLevel, Boolean(title));

  const accordionSections = useMemo(
    () =>
      faqs.map((faq) => (
        <AccordionSection
          key={(uuid as () => string)()}
          headingLevel={accordionHeadingLevel}
          id={(uuid as () => string)()}
          label={faq.question}
        >
          {(parser as (htmlString: string) => JSX.Element)(faq.answer)}
        </AccordionSection>
      )),
    [faqs]
  );

  return (
    <AudiPlatformProvider>
      <Container className="faq-container">
        <InnerContainer>
          {title && (
            <div>
              <Text
                // for backwards compatibility with ADS v1. Remove when settling for v2 colors
                className="no-background"
                as={titleHeadingLevel}
                spaceStackEnd="xl"
                weight="bold"
                variant="order3"
              >
                {title}
              </Text>
            </div>
          )}
          <Accordion>{accordionSections}</Accordion>
        </InnerContainer>
      </Container>
    </AudiPlatformProvider>
  );
};

Faqs.displayName = 'Faqs';
