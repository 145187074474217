import { ContentServiceV1 } from '@oneaudi/content-service';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { FaqsProps } from '../components/Faqs';
import { ContentContext } from './ContentContext';

export interface ContentContextProviderProps {
  readonly contentService: ContentServiceV1;
}

export function ContentContextProvider({
  children,
  contentService,
}: PropsWithChildren<ContentContextProviderProps>): React.ReactElement {
  const [content, setContent] = useState<Partial<FaqsProps>>(
    contentService.getContent() as Partial<FaqsProps>
  );

  useEffect(() => {
    const listener = () => {
      setContent(contentService.getContent() as Partial<FaqsProps>);
    };
    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService]);

  return <ContentContext.Provider value={{ content }}>{children}</ContentContext.Provider>;
}

export function useContent<TContent>(): TContent {
  // throw error if not wrapped in ContentContextProvider
  const { content } = useContext<{ content: TContent }>(ContentContext);
  return content;
}
